(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);throw new Error("Cannot find module '"+o+"'")}var f=n[o]={exports:{}};t[o][0].call(f.exports,function(e){var n=t[o][1][e];return s(n?n:e)},f,f.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
$(window).on('load', function () {

    $('.animation-wrap').hide();

    $(".navbar-toggler").click(function () {
        $("body").toggleClass("nav-active");
    });
    AOS.init();

    var path = window.location.href; // because the 'href' property of the DOM element is the absolute path
    $('.navbar ul a').each(function () {
        if (this.href === path) {
            $(this).addClass('active');
        }
    });
});


jQuery(document).ready(function ($) {

    var isFirstTimeLoad = readCookie("isFirstTime");

    var isFirstTime = true;

    if (isFirstTimeLoad != null || isFirstTimeLoad != undefined || isFirstTimeLoad == "1") {
        isFirstTime = false;
    }

    $('.loader-live').fadeOut('slow', function () {

        if (isFirstTime == true) {
            $('.animation-wrap').show();
            setTimeout(() => {
                $('.animation-wrap').fadeOut("3000");
                createCookie("isFirstTime", 1, 2);
                // createCookie("isFirstTime", 1, 30);

            }, 3000);
        }
        else {
            $('.animation-wrap').hide();
        }
    });

    $(window).scroll(function () {
        if ($(window).scrollTop() >= 200) {
            $('.navbar').addClass('fixed-header');
        }
        else {
            $('.navbar').removeClass('fixed-header');
        }
    });
    // Scroll up

    $(window).scroll(function () {

        if ($(this).scrollTop() > 100) {
            $('.scrollup').fadeIn();
        } else {
            $('.scrollup').fadeOut();
        }
    });

    $('.scrollup').on("click", function () {
        $("html, body").animate({
            scrollTop: 0
        }, 500);
        return false;

    });

});

function createCookie(name, value, days) {
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        var expires = "; expires=" + date.toGMTString();
    } else var expires = "";
    document.cookie = name + "=" + value + expires + "; path=/";
}

function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}
},{}]},{},[1])